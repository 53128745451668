import React, {useEffect} from 'react'
import {Helmet} from "react-helmet"
import Nav from '../components/nav'
import Footer from '../components/footer'
import {gsap} from 'gsap'
import '../stylings/about.css'

// Assets
import {ReactComponent as BackgroundBase} from '../assets/background_colorful_v3.svg'
import {ReactComponent as BackgroundCutout} from '../assets/background_colorful_hand_v3.svg'
import {ReactComponent as Letters} from "../assets/about_letters.svg";
import {ReactComponent as DownArrow} from "../assets/down_arrow.svg";
import AboutSlide_1 from "../assets/AboutSlide_1.png";
import AboutSlide_2 from "../assets/AboutSlide_2.png";

const About = () => {
	useEffect(() => {
		let opening_home_animation = gsap.timeline({})
		opening_home_animation.from('.PageTitle', {display: "none", duration: 2, opacity: 0, delay: .5, ease: "slow(0.7, 0.7, false)"})
		opening_home_animation.from('.PageSubTitle', {display: "none", duration: 1, opacity: 0, ease: "slow(0.7, 0.7, false)"}, "-=1.5")
		opening_home_animation.from(['.NavButton', '.ArrowButton'], {display: "none", duration: 1, opacity: 0, y: () => Math.random()*200, stagger: .25, ease: "slow(0.7, 0.7, false)"}, "-=.5")

		// Get size length of text
		// const logo = document.querySelectorAll("#about_letters path");
		//
		// for (let i=0; i<logo.length; i++) {
		// 	console.log(i + ": " + logo[i].getTotalLength());
		// }
	}, []);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>About Us</title>
				<meta name="description" content="Invictus BCI -- Highest Functionality. Lowest Cost. It's that simple." />
			</Helmet>

			{/*Background Images*/}
			<div className={"BackgroundLayers"}>
				<BackgroundBase className={"PageBackgroundBase"}/>
				<div className={'AboutAccentLayer AccentLayer'}/>
				<BackgroundCutout className={"PageBackgroundCutout"}/>
			</div>

			{/*Title Page*/}
			<div className={'AboutGridLayer MainPageLayer'}>
				<Nav/>
				<div className={'PageTitle'}>
					<Letters/>
				</div>
				<a className={'ArrowButton'} href="#section2">
					<DownArrow href="#section2" className={'MovingDownArrow'}/>
				</a>
				<div className={'AboutBodyPage'} id="section2">
					<div className={"AboutPageMain"}>
						<div className={"AboutText"}>
							<div className={"AboutTextBody"}>
								The prosthetics available today are not sufficient to bridge the gap left by amputation: even though the bionic hand can perform a wide range of movements, the control interface is difficult to use and greatly limits the functionality of the prosthetic. In fact, current control interfaces are so difficult to use, many amputees -- after jumping through all those hoops to obtain the prosthetic in the first place -- give up on using the prosthetic because it is so frustrating
								<br/>
								<br/>
								This is an urgent problem that demands a better solution.
								<br/>
								<br/>
								This is where Invictus BCI comes in: we are creating an affordable neuroprosthetic that can restore near natural
								<br/>
								<br/>
								By leveraging new advances in machine learning and neuroscience, we have created a prosthetic control interface that has more intuitive control, greater movement capability and greater comfort all while being far less expensive.
							</div>
						</div>
						<div className={"AboutVisual"}>
							<img src={AboutSlide_1} alt='What We Do' className={"About_1 AboutImages"}/>
							<img src={AboutSlide_2} alt='What We Do' className={"About_2 AboutImages"}/>
						</div>
					</div>
				</div>
				<div className={'PageSubTitle'}>
					Highest Functionality.<br/> Lowest Cost. <br/>It's that simple.
				</div>
				<Footer/>
			</div>
		</>
	)
}

export default About
